import React from 'react';
import { Card } from 'grommet-controls';
import { Box, Paragraph, Text } from 'grommet';

const Item = ({ item, description, price, size }) => {
  if (!item || !price) {
    return null;
  }

  const Description = description && (
    <Paragraph
      fill
      color="_item_description"
      textAlign="center"
      margin={{
        vertical: 'none',
        horizontal: 'small',
      }}
      size="medium"
    >
      {description}
    </Paragraph>
  );

  let _margin = '10%';
  if (size === 'small') {
    _margin = 'medium';
  }

  return (
    <Card
      elevation="none"
      round="none"
      border={{
        color: 'border',
        size: 'small',
        style: 'dotted',
        side: 'top',
      }}
      background="_back"
    >
      <Box
        fill
        direction="row"
        gap="none"
        justify="between"
        align="baseline"
        margin="none"
        padding="none"
      >
        <Text
          color="_item_name"
          size="22px"
          margin={{
            vertical: 'medium',
            left: _margin,
          }}
        >
          {item}
          {size !== 'small' && Description}
        </Text>
        <Text
          margin={{
            vertical: 'medium',
            right: _margin,
          }}
          color="_item_price"
        >
          {'₹ ' + price}
        </Text>
      </Box>
      {size === 'small' && Description}
    </Card>
  );
};

export default Item;
