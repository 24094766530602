import React from 'react';
import { Collapsible, Layer, Button, List, Box, Text } from 'grommet';
import { Scrollbars } from 'react-custom-scrollbars';

import { FormClose } from 'grommet-icons';

const Sidebar = ({ showSidebar, setShowSidebar, size, state, setState }) => {
  const categories = Object.keys(state.grouped);

  const onCategoryChange = ({ item, index }) => {
    let { grouped } = state;
    setState({ grouped, category: item });
    setShowSidebar(false);
  };

  const SelectList = () => (
    <Scrollbars>
      <List
        background="_side_back"
        border={false}
        data={categories}
        onClickItem={onCategoryChange}
      >
        {(datum) => (
          <Text
            color="_side_cat"
            background="_side_back"
            size="30px"
            alignSelf="start"
          >
            {datum}
          </Text>
        )}
      </List>
    </Scrollbars>
  );

  return (
    <>
      {!showSidebar || size !== 'small' ? (
        <Collapsible direction="horizontal" open={showSidebar}>
          <Box flex width="medium" background="_side_back">
            <SelectList />
          </Box>
        </Collapsible>
      ) : (
        <Layer>
          <Box
            background="_side_back"
            tag="header"
            justify="end"
            align="center"
            direction="row"
          >
            <Button
              icon={<FormClose color="_buttons" size="large" />}
              onClick={() => setShowSidebar(false)}
            />
          </Box>
          <Box fill overflow="auto" background="_side_back">
            <SelectList />
          </Box>
        </Layer>
      )}
    </>
  );
};

export default Sidebar;
