import React, { useState, useEffect } from 'react';
import { readRemoteFile } from 'react-papaparse';
import { Scrollbars } from 'react-custom-scrollbars';

import {
  Anchor,
  ResponsiveContext,
  Heading,
  Button,
  Box,
  Grommet,
} from 'grommet';

import { Menu } from 'grommet-icons';
import { grommet } from 'grommet/themes';
import { deepMerge } from 'grommet/utils';
import { Spinning } from 'grommet-controls';

import Item from './Components/Item';
import Sidebar from './Components/Sidebar';
import { Helmet } from 'react-helmet';

const SHEET_URL =
  'https://docs.google.com/spreadsheets/u/0/d/16_SNEmFbb8VqsrE-vXzRU4MKAAzv4jP_Km4wGDg1m5g/gviz/tq?tqx=out:csv';

const myCustomTheme = deepMerge(grommet, {
  global: {
    focus: {
      outline: 'none',
    },
    font: {
      color: '#9ab3f5',
      family: 'Acme',
    },
    colors: {
      focus: '#D9E896',
      _loader: '#D9E896',
      _bar_back: '#093145',
      _buttons: '#D9E896',
      _logo: '#D9E896',
      _back: '#107896',
      _side_back: '#107896',
      _category: '#D9E896',
      _side_cat: '#D9E896',
      _item_name: '#D9E896',
      _item_description: '#D9E896',
      _item_price: '#D9E896',
    },
  },
  tabs: {
    header: {
      extend: 'overflow: auto; flex-wrap: nowrap; -webkit-scrollbar: hidden',
    },
  },
  list: {
    item: {
      pad: { horizontal: 'xlarge', vertical: 'none' },
      background: ['light-2'],
      border: false,
    },
  },
});

const groupByKey = (list, key) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );

const getItems = ({ grouped, category }, size) => {
  return grouped[category].map((itemDetails, itemIdx) => (
    <Item key={itemIdx} {...itemDetails} size={size} />
  ));
};

const AppBar = (props) => (
  <Box
    direction="row"
    align="center"
    background="_bar_back"
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation="medium"
    style={{ zIndex: '1' }}
    {...props}
  />
);

const App = () => {
  const [state, setState] = useState({
    grouped: { Loading: [] },
    category: 'Loading',
  });
  const [showSidebar, setShowSidebar] = useState(false);

  const getGrouped = (results) =>
    groupByKey(
      results.data.filter((item) => item.available),
      'category'
    );

  useEffect(
    () =>
      (async () =>
        readRemoteFile(SHEET_URL, {
          header: true,
          dynamicTyping: true,
          transformHeader: (header) => header.toLowerCase(),
          complete: (results) => {
            const grouped = getGrouped(results);
            setState({
              grouped,
              category: Object.keys(grouped)[0],
            });
          },
        }))(),
    []
  );
  if (state.category === 'Loading') {
    return (
      <Grommet theme={myCustomTheme} full>
        <Box
          full
          flex="grow"
          background="_back"
          align="center"
          justify="center"
          height="100%"
        >
          <Spinning kind="folding-cube" size="large" color="_loader" />
        </Box>
      </Grommet>
    );
  }
  return (
    <Grommet theme={myCustomTheme} full>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cafe Crossquare | MENU</title>
        <link rel="canonical" href="http://crossquare.space" />
      </Helmet>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box fill>
            <AppBar tag="header" justify="between">
              <Heading color="_logo" level="2" margin="none">
                Cafe Crossquare
              </Heading>
              <Button
                icon={<Menu color="_buttons" />}
                onClick={() => setShowSidebar(!showSidebar)}
              />
            </AppBar>
            <Box
              background="_back"
              direction="row"
              flex
              overflow={{ horizontal: 'hidden' }}
            >
              <Scrollbars>
                <Box flex align="center">
                  <Heading size="large" color="_category">
                    {state.category}
                  </Heading>
                  {getItems(state, size)}
                </Box>
              </Scrollbars>

              <Sidebar
                setShowSidebar={setShowSidebar}
                size={size}
                showSidebar={showSidebar}
                state={state}
                setState={setState}
              />
            </Box>
            <AppBar justify="center" tag="footer">
              <Anchor
                href="https://nemani.dev"
                label="Made by Arjun Nemani"
                color="_logo"
              />
            </AppBar>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};

export default App;
